<template>
  <el-menu
    background-color="#304156"
    text-color="#bfcbd9"
    unique-opened
    router
    :default-active="activeIndex"
    :collapse="$store.state.menu.isCollapse"
    @select="handleSelect"
  >
    <div>
      <div class="logo" v-if="!$store.state.menu.isCollapse">
        <img src="@/assets/logo.png" class="logoImg" />晶容仓库管理系统
      </div>
      <div class="logo" v-else>
        <img src="@/assets/logo.png" style="width: 15px" />
      </div>
    </div>
    <el-menu-item index="index">
      <i class="iconfont icon-shouye-01 icon"></i>
      首页
    </el-menu-item>
    <div v-for="item in menuList" :key="item.id">
      <!-- 一级菜单 -->
      <el-submenu :index="item.url">
        <template slot="title">
          <i :class="'iconfont ' + item.icon + ' icon'"></i>
          {{ item.name }}
        </template>
        <!-- 二级菜单 -->
        <div v-for="m in item.children" :key="m.id">
          <!-- 判断是否还有子菜单 -->
          <div v-if="!m.children">
            <el-menu-item :index="m.url">
              <i :class="'iconfont ' + m.icon + ' icon'"></i>
              {{ m.name }}
            </el-menu-item>
          </div>
          <!-- 存在三级菜单 -->
          <div v-else>
            <el-submenu :index="m.url">
              <template slot="title">
                <i :class="'iconfont ' + m.icon + ' icon'"></i>
                {{ m.name }}
              </template>
              <div v-for="f in m.children" :key="f.id">
                <el-menu-item :index="f.url">
                  <i :class="'iconfont ' + f.icon + ' icon'"></i>
                  {{ f.name }}
                </el-menu-item>
              </div>
            </el-submenu>
          </div>
        </div>
      </el-submenu>
    </div>
  </el-menu>
</template>

<script>
import { getTreeList } from "@/api/login";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // 菜单数据
      menuList: {},
    };
  },
  created() {
    this.activeMenu();
    const savedActiveIndex = localStorage.getItem("activeIndex");
    if (savedActiveIndex) {
      this.$store.commit("setActiveIndex", savedActiveIndex);
    }
  },
  methods: {
    // 根据权限获取所有菜单
    activeMenu() {
      getTreeList().then((res) => {
        this.menuList = res.data;
      });
    },
    handleSelect(index) {
      // 提交 mutation 来更新状态
      this.$store.commit("setActiveIndex", index);
    },
  },
  watch: {
    activeIndex: {
      handler(newActiveIndex) {
        localStorage.setItem("activeIndex", newActiveIndex);
      },
    },
  },
  computed: {
    ...mapGetters(["activeIndex"]), // 从 Vuex 中映射 activeIndex
  },
};
</script>

<style scoped>
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
}
.logoImg {
  width: 23px;
  padding-right: 10px;
}
.el-menu {
  height: 100%;
  width: 200px;
  border-right: none;
  transition: all 20ms;
}

.el-menu--collapse {
  width: 50px;
}

.icon {
  margin-right: 10px;
  color: #bfcbd9;
}
</style>
