import Vue from "vue";
import App from "./App.vue";
// router
import router from "./router";
import "./router/permission";
// vuex
import store from "./store";
// element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// iconfont
import "@/assets/iconfont/iconfont.css";
// vue-print-nb 打印
import Print from 'vue-print-nb'

// 全局方法
import {
  parseTime,
  handleTree,
  addDateRange,
  parseStrEmpty,
  add,
  subtract,
  multiply,
  percentage,
  addNumber
} from "@/utils/jr";

// 全局方法挂载
Vue.prototype.parseTime = parseTime;
Vue.prototype.handleTree = handleTree;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.parseStrEmpty = parseStrEmpty;
Vue.prototype.add = add;
Vue.prototype.subtract = subtract;
Vue.prototype.multiply = multiply;
Vue.prototype.percentage = percentage;
Vue.prototype.addNumber = addNumber;

Vue.use(ElementUI);
Vue.use(Print)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
