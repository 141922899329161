import request from "@/utils/request";

// 获取验证码
export function getCodeImg() {
  return request({
    url: "/captchaImage",
    headers: {
      isToken: false,
    },
    method: "get",
    timeout: 20000,
  });
}

// 登录方法
export function login(loginForm) {
  const data = {
    username: loginForm.username.trim(),
    password: loginForm.password,
    code: loginForm.code,
    uuid: loginForm.uuid,
  };
  return request({
    url: "/login",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 获取动态路由
export function getRouters() {
  return request({
    url: "/getRouters",
    method: "get",
  });
}

export function logout() {
  return request({
    url: "/loginOut",
    method: "get",
  });
}

// 根据用户获取左侧菜单
export function getTreeList() {
  return request({
    url: "/treeList",
    method: "get",
  });
}
