import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import Login from "@/views/login";
// Layout
import Layout from "@/layout";

// 公共路由
export const constantRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "index",
        component: () => import("@/views/index"),
        name: "首页",
        meta: { title: "首页", requireAuth: true },
      },
    ],
  },
];

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((err) => err);
};

export default new VueRouter({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
