const menu = {
  state: {
    // 折叠菜单
    isCollapse: false,
    // 初始化选中菜单的索引
    activeIndex: "index",
  },
  getters: {
    isCollapse: (state) => state.isCollapse,
    activeIndex: (state) => state.activeIndex,
  },
  mutations: {
    setActiveIndex(state, index) {
      state.activeIndex = index;
    },
  },
  actions: {},
};

export default menu;
