<template>
  <div>
    <el-card shadow="always" :body-style="{ padding: '0px', height: '50px' }">
      <button class="button" @click="conllapse">
        <i :class="iconfont" class="button-i"></i>
      </button>
      <el-popover placement="bottom" trigger="hover" popper-class="customPopper">
        <el-button type="text" style="display: block;" @click="updatePassword">修改密码</el-button>
        <el-divider></el-divider>
        <el-button type="text" style="display: block;margin: 0 0;" @click="logout">退出登录</el-button>
        <el-avatar
          slot="reference"
          class="avatar"
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        ></el-avatar>
      </el-popover>
    </el-card>
    <PassWord :drawer.sync="draper"></PassWord>
  </div>
</template>

<script>
import { logout } from "@/api/login";
import { removeToken } from "@/utils/auth";
export default {
  data() {
    return {
      iconfont: "iconfont icon-shouqicaidan",
      draper: false,
    };
  },
  methods: {
    conllapse() {
      this.$store.state.menu.isCollapse = !this.$store.state.menu.isCollapse;
      this.iconfont = this.$store.state.menu.isCollapse
        ? "iconfont icon-zhankaicaidan"
        : "iconfont icon-shouqicaidan";
    },
    // 退出登录
    logout() {
      this.$confirm("确定要退出登录吗？", "提示", { type: "warning" }).then(
        () => {
          logout().then(() => {
            removeToken();
            location.href = "/login";
          });
        }
      );
    },
    // 修改密码
    updatePassword() {
      this.draper = true;
    },
  },
  components: {
    PassWord: () => import("@/layout/components/password"),
  },
};
</script>

<style scoped>
.avatar {
  position: absolute;
  right: 20px;
  top: 6px;
  cursor: pointer;
}

.button {
  height: 50px;
  width: 50px;
  padding: 0px;
  border: none;
  cursor: pointer;
  background-color: #ffffff;
}

.button-i {
  font-size: 25px;
}

.button:hover {
  background-color: #f5f5f5;
}
</style>
<style lang="scss">
.customPopper {
  min-width: 50px !important;
}
.el-divider--horizontal {
  margin: 0 0 !important;
}
</style>